import ModalContainer from "./ModalContainer"
import { useState, useRef, useEffect } from "react"
import Button from "../forms/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { generateUrl, validateEmail, parseLanguage, inputNumber, useStorage } from "../../utils/Helper"
import Axios from "axios"
import { _removeCookie, _setCookie, _storeLoginInfo } from "../../utils/Cookie"
import OutlinedInput from "../forms/OutlinedInput"

interface IProps {
    langCode?: string
    lang: any
    isOpen: boolean
    close: () => void
    type: string
    changeType: (type: 'login' | 'register') => void
    setLogin?: (login: boolean) => void
    from?: 'auth' | 'header'
    savedState?: IAuthState
    registerCode?: string
    emailParam?: string
    redirectRefPage?: string
}

export interface IAuthState {
    [key: string]: string | boolean | number | IOTP | null
    email: string
    password: string
    password_type: 'password' | 'text'
    staySignedIn: boolean
    page: number
    name: string
    phone_number: string
    otp: IOTP
    provider_id: string | null
}

interface IError {
    [key: string]: string
}

interface IOTP {
    [key: string]: string | null
    first: string | null
    second: string | null
    third: string | null
    fourth: string | null
}

const defaultState = {
    page: 1,
    email: '',
    password: '',
    password_type: 'password',
    staySignedIn: false,
    name: '',
    phone_number: '',
    otp: {
        first: null,
        second: null,
        third: null,
        fourth: null
    },
    provider_id: null
}

const LoginModal = ({ langCode, lang, isOpen, type, close, changeType, setLogin, from, savedState, registerCode, emailParam, redirectRefPage }: IProps) => {
    const [state, setState] = useState<IAuthState>(savedState || defaultState as IAuthState)

    const [error, setError] = useState<IError>({})

    // const [landlord_code, setLandlord_code] = useState('')
    const [paramLandlordCode, setParamLandlordCode] = useState('')

    const emailRef = useRef<HTMLInputElement>(null)
    // const passwordRef = useRef<HTMLInputElement>(null)
    // const fullNameRef = useRef<HTMLInputElement>(null)
    const otpRef = useRef<HTMLDivElement>(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    const [canResend, setCanResend] = useState(false)
    const [waitTime, setWaitTime] = useState(60)
    const timerRef = useRef<any>(null)


    useEffect(() => {
        if (from === 'header') {
            if (isOpen) {
                emailRef.current?.focus()
            } else {
                emailRef.current?.blur()
            }
        } else {
            emailRef.current?.focus()
        }
        setState(savedState || defaultState as IAuthState)
    }, [type, isOpen, from])

    useEffect(() => {
        setError({})
    }, [type])

    useEffect(() => {
        return () => {
            if (timerRef.current) clearInterval(timerRef.current)
        }
    }, [state.page])

    useEffect(() => {
        countDown()
    }, [])

    useEffect(() => {
        if (savedState) {
            // if (savedState.provider_id) sendOtp(savedState.provider_id)
            _removeCookie('signup_data')
        }
    }, [savedState])

    useEffect(() => {
        if (waitTime === 0 && timerRef.current) {
            clearInterval(timerRef.current)
            setCanResend(true)
        }
    }, [waitTime])

    useEffect(() => {
        let isValid = true
        if (type === 'login') {
            isValid = validate(undefined, undefined, true)
        } else {
            switch (state.page) {
                case 1:
                    isValid = validate(undefined, undefined, true)
                    break
                // case 2:
                //     isValid = validate(undefined, undefined, true)
                //     break
                case 3:
                    isValid = isOTPFilled()
                    break
            }
        }
        setIsDisabled(!isValid)
    }, [error, state, type])

    useEffect(() => {
        if (emailParam) {
            setState(prev => ({
                ...prev,
                email: emailParam
            }))
        }
        useStorage(() => {
            var code = window.localStorage.getItem('landlord_code') ? window.localStorage.getItem('landlord_code') : ''
            var param_code = `?landlord_code=${code}`
            setParamLandlordCode(param_code)
        })
    }, [])

    const handleChange = (e: React.ChangeEvent<{ name: string, value: string, checked?: boolean, type?: string }>) => {
        const name = e.target.name
        const type = e.target.type
        let value: null | string | boolean = null

        if (type === 'checkbox' && e.target.checked !== undefined) {
            value = e.target.checked
        } else {
            value = name === 'phone_number' ? inputNumber(e.target.value) : e.target.value
            // validate(name, value)
        }

        setError(prev => ({
            ...prev,
            [name]: '',
            failed_login: '',
        }))

        setState(prev => ({
            ...prev,
            [name]: value as string | boolean
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        validate(name, value)
    }

    const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = inputNumber(e.target.value)

        setState(prev => ({
            ...prev,
            otp: {
                ...prev.otp,
                [name]: value
            }
        }))

        if (value) {
            if (otpRef.current) {
                const nextIndex = name === 'first' ? 1 : name === 'second' ? 2 : name === 'third' ? 3 : null
                const childList = otpRef.current.getElementsByTagName('input')
                if (nextIndex) childList[nextIndex].focus()
            }
        }

        setError(prev => ({
            ...prev,
            otp: ''
        }))
    }

    const handleOtpKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key
        const name = e.currentTarget.name
        const value = e.currentTarget.value

        if (key === 'Backspace' && !value) {
            if (otpRef.current) {
                const nextIndex = name === 'first' ? null : name === 'second' ? 0 : name === 'third' ? 1 : 2
                const childList = otpRef.current.getElementsByTagName('input')
                if (nextIndex !== null) childList[nextIndex].focus()
            }
        }
    }

    const togglePassword = (show: boolean) => {
        if (show) {
            setState(prev => ({
                ...prev,
                password_type: 'text'
            }))
        } else {
            setState(prev => ({
                ...prev,
                password_type: 'password'
            }))
        }
    }

    const validate = (key?: string, val?: string | boolean, silentError?: boolean) => {
        const exception = type === 'login' ? ['staySignedIn', 'name', 'phone_number', 'otp'] : ['staySignedIn', 'otp']
        const message = lang.required
        let valid = true
        let keys = key ? { [key]: '' } : state
        for (let key in keys) {
            if (exception.includes(key)) continue
            const value = val || state[key]
            switch (key) {
                case 'email':
                    if (value !== '') {
                        if (!validateEmail(value as string)) {
                            valid = false
                            if (!silentError) {
                                setError(prev => ({
                                    ...prev,
                                    [key]: lang.email_format_invalid
                                }))
                            }
                        } else {
                            if (!silentError) {
                                setError(prev => ({
                                    ...prev,
                                    [key]: ''
                                }))
                            }
                        }
                    } else if (value === '') {
                        valid = false
                    } else {
                        if (!silentError) {
                            setError(prev => ({
                                ...prev,
                                [key]: ''
                            }))
                        }
                    }
                    break
                case 'password':
                    if (value !== '' && (value && value.toString().length < 6)) {
                        valid = false
                        if (!silentError) {
                            setError(prev => ({
                                ...prev,
                                [key]: parseLanguage(lang.minimum_char, ':char', '6')
                            }))
                        }
                    } else if (value === '') {
                        valid = false
                    } else {
                        if (!silentError) {
                            setError(prev => ({
                                ...prev,
                                [key]: ''
                            }))
                        }
                    }
                    break
                case 'phone_number':
                    if (value !== '' && (value && (value.toString().length < 10 || value.toString().length > 25))) {
                        valid = false
                        if (!silentError) {
                            setError(prev => ({
                                ...prev,
                                [key]: parseLanguage(parseLanguage(lang.between_char, ':min-char', '10'), ':max-char', '25')
                            }))
                        }
                    } else if (value === '') {
                        valid = false
                    } else {
                        if (!silentError) {
                            setError(prev => ({
                                ...prev,
                                [key]: ''
                            }))
                        }
                    }
                    break
                default:
                    if (value === '') {
                        valid = false
                    }
                    break
            }
        }
        return valid
    }

    const submit = () => {
        try {
            window.localStorage
        } catch {
            setError(prev => ({
                ...prev,
                failed_login: 'Cookies disabled'
            }))
            return
        }
        if (type === 'login') {
            if (validate()) {
                setIsLoading(true)
                Axios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/login`, {
                    email: state.email,
                    password: state.password,
                    landlord_code: window.localStorage.getItem('landlord_code'),
                    register_code: registerCode,
                    redirect_ref_page: redirectRefPage
                })
                    .then(res => res.data)
                    .then(data => {
                        try {
                            localStorage.setItem('is_owner', data.is_owner)
                            localStorage.setItem('token', data.access_token)
                            localStorage.setItem('account_type', data.type)
                            localStorage.setItem('jendela_username', data.name)
                            if (data.ref_code) {
                                localStorage.setItem('ref', data.ref_code as string) // TODO: jangan lupa diremove kalau logout
                            }
                            localStorage.removeItem('landlord_code')
                            sessionStorage.setItem('toast', JSON.stringify({
                                message: parseLanguage(lang.welcome_with_name, ':name', data.name),
                                options: {
                                    type: 'success',
                                },
                            }))
                            if (data.expires_in) _storeLoginInfo(data.access_token, data.expires_in)
                            if (setLogin) setLogin(true)

                            const prevUrl = sessionStorage.getItem('prevUrl')
                            if (data.redirect_ref_page) {
                                location.href = generateUrl(langCode || 'id', '/account/ref-code')
                            } else {
                                if (prevUrl) {
                                    sessionStorage.removeItem('prevUrl')
                                    location.href = prevUrl
                                } else {
                                    if (from === 'auth') {
                                        location.href = generateUrl(langCode || 'id', '')
                                    } else {
                                        location.reload()
                                    }
                                }
                            }

                            close()
                        } catch {
                            // console.error('Cookies disabled')
                        }
                    })
                    .catch(err => {
                        if (err.response) {
                            if (err.response.status === 401) {
                                setError(prev => ({
                                    ...prev,
                                    failed_login: lang.failed_login_attempt
                                }))
                            }
                            if (err.response.status === 422) {
                                const errors = err.response?.data?.errors
                                if (typeof errors === 'object' && errors !== null) {
                                    for (let key in errors) {
                                        const value = errors[key]
                                        if (Array.isArray(value) && value.length > 0) {
                                            setError(prev => ({
                                                ...prev,
                                                [key]: value[0]
                                            }))
                                        }
                                    }
                                }
                            }
                        }
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        } else {
            switch (state.page) {
                case 1:
                    if (validate()) {
                        setIsLoading(true)
                        Axios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/signup`, {
                            name: state.name,
                            email: state.email,
                            password: state.password,
                            phone: state.phone_number,
                            landlord_code: window.localStorage.getItem('landlord_code'),
                            register_code: registerCode
                        })
                            .then(res => res.data)
                            .then(res => {
                                setState(prev => ({
                                    ...prev,
                                    provider_id: res
                                }))
                                if (from === 'header') {
                                    _setCookie('signup_data', JSON.stringify({
                                        ...state,
                                        page: 3,
                                        provider_id: res,
                                        password: 'not actual password'
                                    }))
                                    window.location.href = generateUrl(langCode === 'en' ? 'en' : 'id', '/signup')
                                } else {
                                    setState(prev => ({
                                        ...prev,
                                        page: 3,
                                    }))
                                }
                            })
                            .catch(err => {
                                if (err.response?.status === 422) {
                                    const errors = err.response?.data?.errors
                                    if (typeof errors === 'object' && errors !== null) {
                                        for (let key in errors) {
                                            const value = errors[key]
                                            if (Array.isArray(value) && value.length > 0) {
                                                setError(prev => ({
                                                    ...prev,
                                                    [key]: value[0]
                                                }))
                                            }
                                        }
                                    }
                                }
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    }
                    break
                case 3:
                    if (isOTPFilled()) {
                        setIsLoading(true)
                        const otpString = state.otp.first! + state.otp.second! + state.otp.third! + state.otp.fourth!
                        Axios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/check-verification`, {
                            otp_code: otpString,
                            provider_id: state.provider_id,
                            register_code: registerCode
                        })
                            .then(res => res.data)
                            .then(res => {
                                try {
                                    localStorage.setItem('is_owner', res.is_owner)
                                    localStorage.setItem('token', res.access_token)
                                    localStorage.setItem('jendela_username', res.name)
                                    if (res.ref_code) {
                                        localStorage.setItem('ref', res.ref_code as string) // TODO: jangan lupa diremove kalau logout
                                    }
                                    localStorage.removeItem('landlord_code')
                                    sessionStorage.setItem('toast', JSON.stringify({
                                        message: parseLanguage(lang.welcome_with_name, ':name', res.name),
                                        options: {
                                            type: 'success',
                                        },
                                    }))
                                    if (res.expires_in) _storeLoginInfo(res.access_token, res.expires_in)
                                    if (setLogin) setLogin(true)

                                    const prevUrl = sessionStorage.getItem('prevUrl')
                                    if (res.redirect_ref_page) {
                                        location.href = generateUrl(langCode || 'id', '/account/ref-code')
                                    } else {
                                        if (prevUrl) {
                                            sessionStorage.removeItem('prevUrl')
                                            location.href = prevUrl
                                        } else {
                                            if (from === 'auth') {
                                                location.href = generateUrl(langCode || 'id', '')
                                            } else {
                                                location.reload()
                                            }
                                        }
                                    }

                                    close()
                                } catch {
                                    // console.error('Cookies disabled')
                                }
                            })
                            .catch(err => {
                                if (err.response?.status === 401) {
                                    setError(prev => ({
                                        ...prev,
                                        otp: lang.otp_error.invalid
                                    }))
                                } else {
                                    setError(prev => ({
                                        ...prev,
                                        otp: lang.otp_error.try_again
                                    }))
                                }
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    } else {
                        setError(prev => ({
                            ...prev,
                            otp: lang.otp_error.empty
                        }))
                    }
                    break
            }
        }
    }

    const toggleType = () => {
        setState(prev => ({
            ...prev,
            page: 1
        }))
        changeType(type === 'login' ? 'register' : 'login')
    }

    const thirdPartyLogin = (url: string) => {
        try {
            const currentUrl = window.location.href
            if (!currentUrl.includes('signin') && !currentUrl.includes('signup') && !currentUrl.includes('auth')) {
                localStorage.setItem('source_url', currentUrl)
            }
        } catch {
            // console.error('Cookies disabled')
        }

        window.location.replace(url)
    }

    const sendOtp = (providerId?: string) => {
        setIsLoading(true)
        Axios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/send-verification`, {
            provider_id: providerId || state.provider_id
        })
            .finally(() => {
                setIsLoading(false)
                countDown()
            })
    }

    const isOTPFilled = () => {
        let valid = true
        for (let key in state.otp) {
            if (state.otp[key] === null || state.otp[key] === '') valid = false
        }
        return valid
    }

    const countDown = () => {
        new Promise(() => {
            setCanResend(false)
            setWaitTime(60)
            timerRef.current = setInterval(() => {
                setWaitTime(prev => prev - 1)
            }, 1000)
        })
    }

    const goBack = () => {
        if (state.page > 1) {
            setState(prev => ({
                ...prev,
                page: prev.page - 1
            }))
        }
    }

    const content = (
        <div className={`flex w-full ${state.page === 3 ? 'pt-6' : 'pt-4'} px-5 pb-2 flex-col relative`}>
            {
                from !== 'auth' &&
                <button className="outline-none focus:outline-none active:outline-none absolute right-0 top-0 -mt-4 -mr-4 self-end px-2 text-black-48 text-opacity-75 transition-all duration-100 ease-linear hover:text-main" onClick={close}>
                    <FontAwesomeIcon icon="times" width="0" size="lg" />
                </button>
            }
            {
                state.page === 1 &&
                <>
                    <p className="text-xl md:text-2xl font-bold text-center text-black-48 mb-8 md:mb-10">{lang.welcome_to_jendela}</p>

                    <div className="mb-10 border-b border-gray-00000034 w-full relative pb-px">
                        <div className="flex flex-row">
                            <div className={`w-20 text-lg text-center pb-2 transition-colors duration-200 ease-linear ${type === 'login' ? 'text-main' : 'text-black-48'} cursor-pointer`} onClick={() => type === 'register' ? toggleType() : undefined}>
                                {lang.login}
                            </div>
                            <div className={`w-20 text-lg text-center pb-2 transition-colors duration-200 ease-linear ${type === 'register' ? 'text-main' : 'text-black-48'} cursor-pointer`} onClick={() => type === 'login' ? toggleType() : undefined}>
                                {lang.sign_up}
                            </div>
                        </div>
                        <div className={`absolute w-20 bg-main left-0 bottom-0 transition duration-150 ease-linear transform ${type === 'register' ? 'translate-x-20' : ''}`} style={{ height: 3 }}></div>
                    </div>
                </>
            }
            {/* <p className={`text-black-48 mb-8 ${from === 'auth' ? 'hidden md:block' : ''} ${state.page === 3 ? 'text-center' : ''}`}>{state.page === 3 ? lang.verification : type === 'login' ? lang.login : lang.sign_up}</p> */}
            {
                state.page !== 3 &&
                // <Input
                //     value={state.email}
                //     placeholder="Email"
                //     name="email"
                //     onChange={handleChange}
                //     className="w-full"
                //     error={!!error.email}
                //     errorText={error.email}
                //     readOnly={type === 'register' && state.page === 2 && from === 'auth'}
                //     helper={(type === 'register' && state.page === 2 && from === 'auth') ? lang.send_signup_email : undefined}
                // />
                <div className="mb-3">
                    <OutlinedInput
                        ref={emailRef}
                        rounded="rounded-3px"
                        borderColor="border-gray-0000001A"
                        value={state.email}
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.email}
                        errorText={error.email}
                        helper={(type === 'register' && state.page === 2 && from === 'auth') ? lang.send_signup_email : undefined}
                        fontSize="text-base"
                        padding="p-3"
                    />
                </div>
            }
            {
                type === 'login' ?
                    <>
                        <div className="mb-4">
                            <OutlinedInput
                                rounded="rounded-3px"
                                borderColor="border-gray-0000001A"
                                value={state.password}
                                placeholder="Password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!error.password}
                                errorText={error.password}
                                fontSize="text-base"
                                padding="p-3"
                                inputProps={{
                                    type: 'password'
                                }}
                            />
                        </div>
                        {
                            error.failed_login &&
                            <p className="text-error">{error.failed_login}</p>
                        }
                        <div className="flex flex-row justify-between mt-4 mb-3">
                            <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", '/recover')} className="ml-auto text-main text-sm hover:text-main-dark cursor-pointer">{lang.forgot_password}</a>
                        </div>
                    </>
                    :
                    <>
                        {
                            state.page === 1 ?
                                <>
                                    <div className="mb-3">
                                        <OutlinedInput
                                            rounded="rounded-3px"
                                            borderColor="border-gray-0000001A"
                                            value={state.name}
                                            placeholder={lang.full_name}
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!error.name}
                                            errorText={error.name}
                                            fontSize="text-base"
                                            padding="p-3"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <OutlinedInput
                                            rounded="rounded-3px"
                                            borderColor="border-gray-0000001A"
                                            value={inputNumber(state.phone_number)}
                                            placeholder={lang.handphone_number}
                                            name="phone_number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!error.phone_number}
                                            errorText={error.phone_number}
                                            fontSize="text-base"
                                            padding="p-3"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <OutlinedInput
                                            rounded="rounded-3px"
                                            borderColor="border-gray-0000001A"
                                            value={state.password}
                                            placeholder="Password"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!error.password}
                                            errorText={error.password}
                                            rightIcon={
                                                state.password_type === 'password' ?
                                                    <FontAwesomeIcon icon="eye" width="0" className="text-gray-88 cursor-pointer" onClick={() => togglePassword(true)} />
                                                    :
                                                    <FontAwesomeIcon icon="eye-slash" width="0" className="text-gray-88 cursor-pointer" onClick={() => togglePassword(false)} />
                                            }
                                            fontSize="text-base"
                                            padding="p-3"
                                            inputProps={{
                                                type: state.password_type
                                            }}
                                        />
                                    </div>
                                </>
                                :
                                state.page === 3 ?
                                    <>
                                        <img src="https://assets.jendela360.com/jendela/assets/images/svg/half-fill-envelope.svg" width="48" height="29" alt="envelope" className="mb-6 self-center" />
                                        <p className="font-semibold text-black-48 text-2xl text-center mb-4">{lang.input_verification_code}</p>
                                        <p className="text-sm text-black-48 font-light text-center mb-5">
                                            {lang.verification_sent}<br />
                                            <span className="font-medium">{state.email}</span><br />
                                            {lang.verification_hint}
                                        </p>
                                        <p className="text-black-48 text-center mb-3">{lang.verification_code}</p>
                                        <div className="flex flex-col mb-16">
                                            <div ref={otpRef} className="flex items-center justify-center">
                                                <input className={`mx-2 outline-none focus:outline-none text-3xl text-center w-1/4 min-w-11 max-w-14 border-b ${state.otp.first ? 'border-main' : 'border-gray-dd'} focus:border-main`} name="first" minLength={1} maxLength={1} size={1} value={state.otp.first ? inputNumber(state.otp.first) : ''} onChange={handleOtpChange} onKeyDown={handleOtpKeyDown} />
                                                <input className={`mx-2 outline-none focus:outline-none text-3xl text-center w-1/4 min-w-11 max-w-14 border-b ${state.otp.second ? 'border-main' : 'border-gray-dd'} focus:border-main`} name="second" minLength={1} maxLength={1} size={1} value={state.otp.second ? inputNumber(state.otp.second) : ''} onChange={handleOtpChange} onKeyDown={handleOtpKeyDown} />
                                                <input className={`mx-2 outline-none focus:outline-none text-3xl text-center w-1/4 min-w-11 max-w-14 border-b ${state.otp.third ? 'border-main' : 'border-gray-dd'} focus:border-main`} name="third" minLength={1} maxLength={1} size={1} value={state.otp.third ? inputNumber(state.otp.third) : ''} onChange={handleOtpChange} onKeyDown={handleOtpKeyDown} />
                                                <input className={`mx-2 outline-none focus:outline-none text-3xl text-center w-1/4 min-w-11 max-w-14 border-b ${state.otp.fourth ? 'border-main' : 'border-gray-dd'} focus:border-main`} name="fourth" minLength={1} maxLength={1} size={1} value={state.otp.fourth ? inputNumber(state.otp.fourth) : ''} onChange={handleOtpChange} onKeyDown={handleOtpKeyDown} />
                                            </div>
                                            {
                                                error.otp &&
                                                <p className="text-error text-xs text-center mt-3">{error.otp}</p>
                                            }
                                        </div>
                                    </>
                                    : null
                        }
                    </>
            }
            <button
                onClick={submit}
                className={`p-4 text-base font-semibold text-white rounded-md leading-none transition-all duration-100 ease-linear ${(isDisabled || isLoading) ? 'bg-main-disabled cursor-not-allowed' : 'bg-main hover:bg-main-dark'} outline-none focus:outline-none`}
                disabled={isDisabled || isLoading}
                name="login"
            >
                {state.page === 3 ? lang.verification : type === 'login' ? lang.login : lang.sign_up}
            </button>
            {/* <Button
                variant="contained"
                color='main'
                size="lg"
                fontSize="text-sm"
                onClick={submit}
            >
                {state.page === 3 ? lang.verification : type === 'login' ? lang.login : lang.sign_up}
            </Button> */}
            {
                state.page === 3 ?
                    isLoading ?
                        <p className="text-xs text-black-48 font-light text-center mt-3">
                            {lang.sending_code}
                        </p>
                        : canResend ?
                            <p className="text-xs text-black-48 font-light text-center mt-3">
                                {lang.no_code} <span onClick={() => sendOtp()} className="text-main cursor-pointer">{lang.resend_code}</span>
                            </p>
                            :
                            <p className="text-xs text-black-48 font-light text-center mt-3">
                                {lang.wait_resend_1}&nbsp;<span className="font-bold">{waitTime.toString()}&nbsp;{lang.seconds}</span>&nbsp;{lang.wait_resend_2}
                            </p>
                    : null
            }
            {
                type === 'register' && state.page === 1 ?
                    <div className="mt-5 text-xs text-center text-gray-a4">
                        By signing up, you agree to Jendela360 <br />
                        {/* TODO: Privacy Policy dihilangkan dulu */}
                        <a className="text-main hover:text-main-dark" target="__blank" href={generateUrl(langCode === 'en' ? 'en' : 'id', '/terms')}>Terms of Conditions</a>
                    </div>
                    : null
            }
            {
                type === 'login' || (type === 'register' && state.page === 1) ?
                    <>
                        <div className="relative border-t border-gray-C5C5C5 w-full my-8">
                            <span
                                className="absolute transform -translate-x-1/2 -translate-y-1/2 pb-1 bg-white text-center text-sm text-black-48 font-light lowercase"
                                style={{ left: '50%', width: 60 }}
                            >
                                {lang.atau}
                            </span>
                        </div>
                        <Button
                            onClick={() => thirdPartyLogin(registerCode ? `${process.env.NEXT_PUBLIC_PHP_API_URL}/site/auth/google?register_code=${registerCode}` : `${process.env.NEXT_PUBLIC_PHP_API_URL}/site/auth/google${paramLandlordCode}`)}
                            variant="outlined"
                            borderColor="border-gray-cf"
                            size="lg"
                            fontSize="text-base"
                            classes="flex flex-row text-gray-68 items-center justify-center"
                        >
                            <img src="https://assets.jendela360.com/jendela/assets/images/svg/google.svg" alt="Google Logo" className="mr-2" style={{ height: 18 }} />
                            <p>{lang.google_login}</p>
                        </Button>
                        {/* <Button target="popup" onClick={() => toggleNewWindow(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/auth/facebook`)} variant="outlined" borderColor="border-gray-cf" size="lg" fontSize="text-sm" classes="flex flex-row text-black-48 items-center justify-center mb-8">
                            <img src="https://assets.jendela360.com/jendela/assets/images/svg/facebook.svg" alt="Google Logo" className="mr-2" style={{ height: 18 }} />
                            <p>Facebook</p>
                        </Button> */}
                        {/* <p onClick={() => toggleType()} className="text-main hover:text-main-dark underline text-sm text-center flex self-center cursor-pointer">
                            {type === 'register' ?
                                lang.have_account
                                :
                                lang.sign_up
                                // <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", "/signup")}>
                                //     {lang.sign_up}
                                // </a>
                            }
                        </p> */}
                    </>
                    : null
            }
        </div>
    )

    return (
        from === 'auth' ?
            <div className={`flex w-full md:w-auto pb-4 md:p-8 md:rounded bg-white md:shadow-md ${state.page === 3 ? 'xs:pt-8 md:min-w-500px md:max-w-500px' : 'md:min-w-440px md:max-w-440px'}`}>
                {content}
            </div>
            :
            <ModalContainer
                isOpen={isOpen}
                close={close}
                containerClassName="md:min-w-440px md:max-w-440px"
                positionClassname="items-start justify-center"
                containerMargin="mt-6"
                customZIndex={102}
            >
                {content}
            </ModalContainer>
    )
}

export default LoginModal