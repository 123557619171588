import classes from "./ModalContainer.module.css"
import { createPortal } from 'react-dom'
import { ReactNode, useEffect } from "react"
import { useMounted } from "../../hooks/MountedHooks"

export interface BaseModalContainerProps {
    isOpen: boolean
    background?: string
    fullScreen?: boolean
    noPadding?: boolean
    notCentered?: boolean
    fullScreenKeyboard?: boolean
    isNested?: boolean
    isNestedOpen?: boolean
    maxWidth?: number
    close?: () => void
    parentClassName?: string
    containerClassName?: string
    positionClassname?: string
    containerMargin?: string
    withoutBackDrop?: boolean
    parentOverflowHidden?: boolean
    transition?: 'fade' | 'vertical'
    customZIndex?: number
}

interface ModalContainerProps extends BaseModalContainerProps {
    children: ReactNode
}

const ModalContainer = ({ children, isOpen, background, customZIndex, fullScreen, noPadding, notCentered, fullScreenKeyboard, isNested, isNestedOpen, maxWidth, close, parentClassName, containerClassName, positionClassname, containerMargin, withoutBackDrop, transition, parentOverflowHidden }: ModalContainerProps) => {
    const isMounted = useMounted()

    useEffect(() => {
        if (!isNested) {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
        return () => { document.body.style.overflow = 'auto' }
    }, [])

    useEffect(() => {
        if (!isNested) {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
    }, [isOpen])

    return (
        isMounted ? createPortal(
            <div className={`${parentClassName || ''} ${classes.container} ${isOpen ? classes.open : classes.close} ${isNestedOpen ? 'overflow-none' : parentOverflowHidden ? 'overflow-hidden' : 'overflow-auto'}`} style={{ zIndex: customZIndex ? customZIndex : parentClassName ? 99 : 50 }}>
                <div className={`relative min-h-full min-w-full ${fullScreen ? 'w-full h-full' : ''} flex ${positionClassname || 'md:items-center justify-center'}`}>
                    {
                        withoutBackDrop ?
                            null
                            :
                            <div className={`${classes.backdrop}`} style={{ justifyContent: notCentered ? 'flex-start' : 'center', backgroundColor: fullScreenKeyboard ? 'white' : undefined }} onClick={() => {
                                if (typeof close !== 'undefined') {
                                    close()
                                }
                            }} />
                    }
                    <div
                        className={`${transition ? classes[transition] : ''} ${containerClassName || ''} ${classes.card} ${isOpen ? classes['card-open'] : ''} ${background || 'bg-white'} ${fullScreen ? 'w-full h-full' : fullScreenKeyboard ? 'w-full' : ''} ${noPadding ? '' : 'p-8'} ${fullScreen || fullScreenKeyboard ? 'px-0' : ''} rounded ${fullScreenKeyboard ? undefined : notCentered ? 'my:75px mx:0' : containerMargin || ''}`}
                        style={{
                            maxWidth
                        }}
                    >
                        {children}
                    </div>
                </div>
            </div>,
            document.body
        ) : null
    )
}

export default ModalContainer