import React, { useImperativeHandle, useRef } from "react"
import { HTMLProps, ReactNode } from "react"

import className from './OutlinedInput.module.css'

export interface OutlinedInputProps {
    textArea?: boolean
    value?: string
    name?: string
    placeholder?: string
    label?: string | ReactNode
    error?: boolean
    errorText?: string
    onChange?: (e: any) => void
    onKeyDown?: (e: any) => void
    onBlur?: (e: any) => void
    leftIcon?: React.ReactNode
    leftFunction?: Function
    rightIcon?: React.ReactNode
    rightFunction?: Function
    isRightHide?: boolean
    classes?: string
    style?: Object
    notRounded?: boolean
    /** must include border-* */
    borderColor?: string
    helper?: string
    select?: boolean
    children?: ReactNode
    /** it will override all default padding size */
    padding?: string
    fontSize?: string
    inputProps?: Partial<HTMLProps<HTMLInputElement>>
    rounded?: string
    backgroundColor?: string
    fullWidth?: boolean
}

export interface IOutlinedInput {
    focus: () => void
    blur: () => void
}

const OutlinedInput = React.forwardRef(({ textArea, value, name, placeholder, label, error, errorText, onChange, onKeyDown, onBlur, leftIcon, leftFunction, rightIcon, rightFunction, isRightHide, classes, style, notRounded, borderColor, helper, select, children, padding, fontSize, inputProps, rounded, backgroundColor, fullWidth }: OutlinedInputProps, ref: React.Ref<IOutlinedInput>) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current?.focus()
        },
        blur: () => {
            inputRef.current?.blur()
        },
    }))

    return (
        <>
            {
                label ?
                    <OutlinedInputLabel label={label} error={error} />
                    : null
            }
            <div className={`${fullWidth ? 'w-full' : ''} flex flex-row items-center ${backgroundColor || 'bg-white'} transition-all duration-100 ease-linear ${error ? className.containerError : className.containerNormal} ${classes || `border ${!notRounded ? rounded || 'rounded-md' : ''} ${error ? 'border-error' : borderColor || ''} ${padding || 'py-2 px-3'}`}`} style={style}>
                {
                    leftIcon ?
                        <span onClick={() => leftFunction ? leftFunction() : null} className="flex items-center justify-center">
                            {leftIcon}
                        </span>
                        : null
                }
                {
                    textArea ?
                        <textarea
                            placeholder={placeholder || ''}
                            value={value}
                            name={name}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            className={`w-full ${backgroundColor || 'bg-white'} ${fontSize || 'text-sm'} outline-none ${error && 'text-error'} ${leftIcon ? 'pl-3' : ''} ${rightIcon ? 'pr-3' : ''}`}
                        />
                        : select ?
                            <select
                                placeholder={placeholder || ''}
                                value={value}
                                name={name}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onBlur={onBlur}
                                className={`w-full ${backgroundColor || 'bg-white'} ${fontSize || 'text-sm'} outline-none ${error && 'text-error'} ${leftIcon ? 'pl-3' : ''} ${rightIcon ? 'pr-3' : ''}`}
                            >
                                {children}
                            </select>
                            :
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder={placeholder || ''}
                                value={value}
                                name={name}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onBlur={onBlur}
                                className={`w-full ${backgroundColor || 'bg-white'} ${fontSize || 'text-sm'} outline-none ${error && 'text-error'} ${leftIcon ? 'pl-3' : ''} ${rightIcon ? 'pr-3' : ''}`}
                                {...inputProps}
                            />
                }
                {
                    rightIcon ?
                        <span
                            onClick={() => rightFunction ? rightFunction() : null}
                            className={`${isRightHide ? 'invisible' : ''}`}
                        >
                            {rightIcon}
                        </span>
                        : null
                }
            </div>
            {
                (error && errorText) ?
                    <p className="text-sm text-error ml-1">{errorText}</p>
                    : !!helper ?
                        <p className="text-sm ml-1">{helper}</p>
                        : null
            }
        </>
    )
})

export const OutlinedInputLabel = (props: { error?: boolean, label: string | ReactNode }) => (
    <label className={`block pb-2 ${props.error && 'text-error'}`}>{props.label}</label>
)

export default OutlinedInput